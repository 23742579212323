function positionElement(elem, check) {
    const element = document.querySelector(elem);
    const parentSelector = check();
    const parent = document.querySelector(parentSelector);
    // parent.insertBefore(element, parent.children[parent.children.length - 1]);
    parent.appendChild(element);
    // element.parentNode.insertBefore(newElement, element.nextSibling);
}

module.exports = positionElement;
