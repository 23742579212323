require('../css/main.scss');

import 'swiper/css';
import Swiper, { Pagination, Navigation } from 'swiper';

// const Swiper = require('swiper');

const mobileMenu = require('./func/mobile-menu');
const positionElement = require('./func/position-element');
const lazyLoadYoutube = require('./func/lazyload-youtube');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {
    mobileMenu('.c-mobile-menu', '.c-mobile-menu_button');
    const posVid = () =>
        positionElement('.c-vid-desc_vid', () =>
            window.innerWidth <= 992 ? '.c-vid-desc_vid-mobile' : '.c-vid-desc_vid-desktop'
        );

    posVid();
    window.addEventListener('resize', posVid);

    const swiperVideos = new Swiper('.swiper-videos', {
        // Optional parameters
        // direction: 'vertical',
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 20,
        // centeredSlides: true,

        modules: [Navigation],

        // Navigation arrows
        navigation: {
            nextEl: '.c-vid-desc_nav-btn--right',
            prevEl: '.c-vid-desc_nav-btn--left'
        }
        // breakpoints: {
        //     // when window width is >= 320px
        //     361: {
        //         slidesPerView: '1.3',
        //         spaceBetween: 30
        //     }
        // }

        // // And if we need scrollbar
        // scrollbar: {
        //     el: '.swiper-scrollbar'
        // }
    });

    swiperVideos.on('slideChange', function () {
        const inactiveIndex = 1 - this.activeIndex;
        const iframeLoaded = !!this.slides[this.activeIndex].querySelector('iframe');
        !iframeLoaded && this.slides[this.activeIndex].click();
        this.slides[inactiveIndex]
            .querySelector('iframe')
            .contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    });

    const swiperFaq = new Swiper('.swiper-faq', {
        // Optional parameters
        // direction: 'vertical',
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        modules: [Pagination],

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }

        // Navigation arrows
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev'
        // },

        // // And if we need scrollbar
        // scrollbar: {
        //     el: '.swiper-scrollbar'
        // }
    });

    const swiperReviews = new Swiper('.swiper-reviews', {
        // Optional parameters
        // direction: 'vertical',
        loop: false,

        modules: [Navigation],

        // Navigation arrows
        navigation: {
            nextEl: '.c-reviews_nav--right',
            prevEl: '.c-reviews_nav--left'
        }

        // // And if we need scrollbar
        // scrollbar: {
        //     el: '.swiper-scrollbar'
        // }
    });

    document.querySelectorAll('.c-swidget_btn-next').forEach(btn => {
        btn.addEventListener('click', e => swiperFaq.slideNext());
    });

    document.querySelector('.c-form').addEventListener('submit', async e => {
        e.preventDefault();
        const classHidden = 't-display-none';
        const date = new Date();
        const form = e.target;
        const loader = document.querySelector('.c-form_loader');
        const submitButton = document.querySelector('.c-form_submit-wrapper');
        const successMessage = document.querySelector('.c-form_message--success');
        const errorMessage = document.querySelector('.c-form_message--error');
        const data = {
            name: form.elements.name.value,
            phone: form.elements.phone.value,
            city: form.elements.city.value,
            address: form.elements.address.value,
            secret: (
                new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).getTime() /
                1000
            ).toString(36)
        };

        let hasError = false;

        try {
            errorMessage.classList.add(classHidden);
            submitButton.classList.add(classHidden);
            loader.classList.remove(classHidden);

            const response = await fetch(process.env.ENDPOINT_ENQUIRY, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const json = await response.json();

            if (json.data?.success) {
                form.style.display = 'none';

                successMessage.classList.remove(classHidden);
                successMessage.innerHTML = `
                <div class="c-form_message-title">Успешна Заявка</div>
                <div class="c-form_message-content">
                    <p>
                        Благодарим Ви, вашата заявка беше приета успешно. 
                        Ще се свържем с Вас на посочения телефон за потвържение.
                    </p>
                </div>`;
            } else {
                hasError = true;
            }
        } catch (error) {
            hasError = true;
        }

        loader.classList.add(classHidden);

        if (hasError) {
            submitButton.classList.remove(classHidden);
            errorMessage.classList.remove(classHidden);
            errorMessage.innerHTML = `
                    <div class="c-form_message-title">Възникнала Грешка</div>
                    <div class="c-form_message-content">
                        <p>
                            Възникнала е грешка при изпращането за заявката Ви.
                            Заявката не е приета, моля опитайте пак по-късно.
                        </p>
                    </div>`;
        }
    });

    setTimeout(() => {
        lazyLoadYoutube();

        const bannerVideo = document.querySelector('.c-banner-section_video source');
        bannerVideo.src = bannerVideo.dataset.src;
        bannerVideo.parentElement.load();
    }, 10);
}
