function mobileMenu(selectorMenu, selectorButton) {
    const menu = document.querySelector(selectorMenu);
    const button = document.querySelector(selectorButton);

    if (!menu || !button) {
        throw new Error('Unable to initialize mobile menu');
    }

    button.addEventListener('click', e =>
        menu.classList.toggle(selectorMenu.substring(1) + '--open')
    );

    document.querySelectorAll('.c-mobile-menu_link').forEach(link => {
        link.addEventListener('click', e => {
            menu.classList.remove(selectorMenu.substring(1) + '--open');
        });
    });
}

module.exports = mobileMenu;
