module.exports = function lazyLoadYoutube() {
    const videos = document.querySelectorAll('.youtube');

    for (var i = 0; i < videos.length; i++) {
        const video = videos[i];

        video.addEventListener('click', function () {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '');
            iframe.setAttribute(
                'allow',
                'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
            );
            iframe.setAttribute('width', '100%');
            iframe.setAttribute('height', '100%');
            iframe.setAttribute(
                'src',
                `https://www.youtube-nocookie.com/embed/${video.dataset.ytid}?autoplay=1&enablejsapi=1`
            );

            this.innerHTML = '';
            this.appendChild(iframe);
        });
    }
};
